var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{attrs:{"color":"primary","show":_vm.isShowPopup,"close-on-backdrop":false,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("Document Mapping")]),_c('CButtonClose',{staticClass:"text-black",on:{"click":function($event){return _vm.onCancel()}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn px-4 btn-outline-primary",on:{"click":function($event){return _vm.onCancel()}}},[_vm._v("Close")]),_c('CButton',{staticClass:"px-4 btn-primary",on:{"click":function($event){return _vm.onSave()}}},[_vm._v("Save")])]},proxy:true}])},[_c('div',{staticClass:"p-2"},[_c('h6',{staticClass:"mb-3"},[_vm._v(" Select the Document to be mapped with the "+_vm._s(_vm.document_type_id ? _vm.document_type_id == 12 ? "Qualification" : "License" : "Speciality")+": "),_c('strong',[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"text-primary pt-1",staticStyle:{"font-weight":"600","background-color":"whitesmoke"}},[_c('span',{staticClass:"ml-3"},[_vm._v(" Documents ")])]),_vm._l((_vm.filteredDocuments),function(i){return _c('div',{key:i.document_id,staticClass:"mt-1 border-bottom",staticStyle:{"background-color":"whitesmoke"},style:(`${
          _vm.selected.includes(i.document_id)
            ? 'background-color: #D0F0C0'
            : 'background-color: whitesmoke'
        }`)},[_c('CRow',{staticClass:"ml-2"},[_c('div',{staticClass:"doc-table col col-1"},[_c('p',{staticClass:"head mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"type":"checkbox"},domProps:{"value":i.document_id,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,i.document_id)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=i.document_id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}})])]),_c('div',{staticClass:"doc-table col col-4"},[_c('p',{staticClass:"head"},[_vm._v("Document Type")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(i.label))])]),_c('div',{staticClass:"doc-table col col-3"},[_c('p',{staticClass:"head"},[_vm._v("Document Name")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(i.name))])]),_c('div',{staticClass:"doc-table col col-3"},[_c('p',{staticClass:"head"},[_vm._v("Uploaded Date")]),_c('p',{staticClass:"data"},[_vm._v(_vm._s(i.updatedDate))])])])],1)}),(!_vm.filteredDocuments.length)?_c('div',{style:(_vm.isMobile
            ? 'background-color: whitesmoke; height: 60px'
            : 'background-color: whitesmoke; height: 40px')},[(_vm.isCustomerAdmin)?_c('h5',{staticClass:"text-center mt-2 p-2",style:(_vm.isMobile ? 'font-size:13px' : 'font-size:15px')},[_vm._v(" No document found - you may upload and link this document in Document Information section ")]):_c('h5',{staticClass:"text-center mt-2 p-2",style:(_vm.isMobile ? 'font-size: 13px' : 'font-size: 15px')},[_vm._v(" No document found - you may upload and link this document in Screen 3 ")])]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }