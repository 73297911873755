<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">{{ modalTitle }}</h6>
        <CButtonClose @click="onCancel()" class="text-black" />
      </template>
      <template #footer>
        <CButton class="btn px-4 btn-outline-primary" @click="onCancel()">{{
          confirmation ? "Close" : "No"
        }}</CButton>
        <CButton
          class="px-4 btn-primary"
          v-if="!confirmation"
          @click="onConfirm()"
          >Yes</CButton
        >
        <CButton class="px-4 btn-primary" v-else @click="onSave()"
          >Save</CButton
        >
      </template>
      <div>
        <div v-if="!confirmation">
          <h6 class="text-center">
            Do you want to link this Certificate -
            <strong>{{ label }}</strong> with a
            {{ document_type_id == 9 ? "License" : "Qualification" }} ?
          </h6>
        </div>
        <div v-else>
          <h6 class="mb-3">
            {{ modalContent }} <strong>{{ label }}</strong>
          </h6>
          <div
            class="text-primary pt-1"
            style="font-weight: 600; background-color: whitesmoke"
          >
            <span class="ml-3">
              {{ document_type_id == 9 ? "Licenses" : "Qualifications" }}
            </span>
          </div>
          <div v-if="document_type_id == 9">
            <div
              v-for="i in licenses"
              :key="i.candidate_license_id"
              style="background-color: whitesmoke"
              class="mt-1 border-bottom"
              :style="`${
                selected.candidate_license_id == i.candidate_license_id
                  ? 'background-color: #D0F0C0'
                  : 'background-color: whitesmoke'
              }`"
            >
              <CRow class="ml-2">
                <div class="doc-table col col-1">
                  <p class="head mt-3">
                    <input
                      name="candidate_license_id"
                      type="radio"
                      :value="i.candidate_license_id"
                      :checked="
                        selected.candidate_license_id == i.candidate_license_id
                      "
                      @input="
                        handleRadio(
                          'candidate_license_id',
                          i.candidate_license_id
                        )
                      "
                    />
                  </p>
                </div>
                <div class="doc-table col col-3">
                  <p class="head">License Valid In</p>
                  <p class="data">{{ i.country_name }}</p>
                </div>
                <div class="doc-table col col-4">
                  <p class="head">Medical License Board</p>
                  <p class="data">{{ i.issue_authority_name }}</p>
                </div>
                <div class="doc-table col col-3">
                  <p class="head">Issue/Registration Date</p>
                  <p class="data">{{ i.issue_date }}</p>
                </div>
              </CRow>
            </div>
            <div
              v-if="!licenses.length"
              style="background-color: whitesmoke; height: 40px"
            >
              <h5 class="text-center mt-2 pt-2">No License found</h5>
            </div>
          </div>

          <div v-if="document_type_id == 12">
            <div
              v-for="i in SortedQualifications"
              :key="i.candidate_qualification_id"
              style="background-color: whitesmoke"
              class="mt-1 border-bottom"
              :style="`${
                selected.candidate_qualification_id ==
                i.candidate_qualification_id
                  ? 'background-color: #D0F0C0'
                  : 'background-color: whitesmoke'
              }`"
            >
              <CRow class="ml-2">
                <div class="doc-table col col-1">
                  <p class="head mt-3">
                    <input
                      name="candidate_qualification_id"
                      type="radio"
                      :value="i.candidate_qualification_id"
                      :checked="
                        selected.candidate_qualification_id ==
                        i.candidate_qualification_id
                      "
                      @input="
                        handleRadio(
                          'candidate_qualification_id',
                          i.candidate_qualification_id
                        )
                      "
                    />
                  </p>
                </div>
                <div class="doc-table col col-6">
                  <p class="head">Qualification</p>
                  <p class="data">{{ i | qualificationName }}</p>
                </div>
                <div class="doc-table col col-5">
                  <p class="head">Country Obtained</p>
                  <p class="data">{{ i.country_name }}</p>
                </div>
                <div class="doc-table col col-1"></div>
                <div class="doc-table col col-5">
                  <p class="head">Month and Year</p>
                  <p class="data">
                    {{ formatDate(i.month_awarded, i.year_awarded) }}
                  </p>
                </div>
              </CRow>
            </div>
            <div
              v-if="!SortedQualifications.length"
              style="background-color: whitesmoke; height: 40px"
            >
              <h5 class="text-center mt-2 pt-2">No Qualification found</h5>
            </div>
          </div>
        </div>
      </div>
    </CModal>
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "QualificationMappingModal",
  props: [
    "isShowPopup",
    "document_type_id",
    "label",
    "modalTitle",
    "modalContent",
    "candidate_document_id",
  ],
  data: () => ({
    confirmation: false,
    selected: {
      candidate_license_id: [],
      candidate_qualification_id: null,
    },
  }),
  watch: {
    isShowPopup() {
      if (this.document_type_id == 9) {
        if (!this.licenses?.length) this.onCancel();
      }
      if (this.document_type_id == 12) {
        if (!this.SortedQualifications?.length) this.onCancel();
      }
      this.clearFields();
    },
  },
  computed: {
    ...mapGetters([
      "getCandidateQualification",
      "getLicense",
      "candidateProfile",
    ]),
    licenses() {
      return this.getLicense?.length
        ? this.getLicense.map(
            ({
              candidate_license_id,
              country_name,
              issue_date,
              issue_authority_id,
              issue_authority_name,
              other_issue_authority,
              document_ids,
            }) => {
              return {
                candidate_license_id: candidate_license_id,
                country_name: country_name !== null ? country_name : "--",
                issue_date: issue_date !== null ? issue_date : "--",
                issue_authority_name:
                  !issue_authority_id && other_issue_authority
                    ? "Other - " + other_issue_authority
                    : issue_authority_name,
                document_ids: document_ids,
              };
            }
          )
        : null;
    },
    Qualifications() {
      if (this.getCandidateQualification) {
        return (
          this.getCandidateQualification?.length &&
          this.getCandidateQualification
            .filter(({ qualification_id }) => qualification_id)
            .map(
              ({
                candidate_qualification_id,
                qualification_id,
                qualification_name,
                qualification,
                country_name,
                year_awarded,
                month_awarded,
                issue_authority_id,
                issue_authority_name,
                qualification: { qualification_with_acronym },
                other_issue_authority,
                other_qualification_name,
                document_ids,
              }) => {
                return {
                  candidate_qualification_id: candidate_qualification_id,
                  qualification_id:
                    qualification_id !== null ? qualification_id : "--",
                  qualification_name:
                    qualification_name !== null ? qualification_name : "--",
                  qualification: qualification,
                  country_name: country_name !== null ? country_name : "--",
                  year_awarded: year_awarded,
                  month_awarded: month_awarded,
                  issue_authority_id:
                    issue_authority_id !== null ? issue_authority_id : "Others",
                  issue_authority_name:
                    issue_authority_name !== null
                      ? issue_authority_name
                      : "Others",
                  other_issue_authority:
                    other_issue_authority !== null
                      ? other_issue_authority
                      : "--",
                  qualification_with_acronym:
                    qualification_with_acronym !== null
                      ? qualification_with_acronym
                      : null,
                  other_qualification_name: other_qualification_name,
                  document_ids: document_ids,
                };
              }
            )
        );
      } else {
        return false;
      }
    },
    SortedQualifications() {
      if (this.Qualifications) {
        return this.Qualifications.sort((a, b) => {
          return b.qualification_name - a.qualification_name;
        }).sort((a, b) => {
          return b.year_awarded - a.year_awarded;
        });
      }
      return null;
    },
  },
  filters: {
    qualificationName(data) {
      if (data !== null)
        return data.qualification_id != 241
          ? data.qualification_with_acronym
          : `Other${
              data.other_qualification_name
                ? " - " + data.other_qualification_name
                : ""
            }`;
      return "--";
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "updateLicense",
      "fetchLicense",
      "updateQualification",
      "fetchQualification",
      "fetchUploadedDocument",
      "resetLastUploadedDocs",
    ]),
    formatDate(month, year) {
      if (month && year) {
        let date = month ? new Date(`${year},${month},01`) : "--";
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
    handleRadio(name, value) {
      this.selected[`${name}`] = value;
    },
    onCancel() {
      this.$parent.linkDocument.isShowPopup = false;
      this.resetLastUploadedDocs();
    },
    onConfirm() {
      this.confirmation = true;
    },
    onSave() {
      if (
        !(
          this.selected.candidate_license_id ||
          this.selected.candidate_qualification_id
        )
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please select any data to link!",
        });
        return;
      }
      if (this.document_type_id == 9) {
        let data = this.licenses?.filter(
          (val) =>
            val.candidate_license_id == this.selected.candidate_license_id
        )[0];
        let payload = {
          document_ids: data.document_ids,
          candidate_uid: this.candidateProfile?.candidate_uid,
          candidate_license_id: data.candidate_license_id,
        };
        payload?.document_ids?.length
          ? payload.document_ids.push(this.candidate_document_id)
          : (payload.document_ids = [this.candidate_document_id]);
        this.updateLicense(payload).then((res) => {
          this.fetchLicense();
          this.$parent.linkDocument.isShowPopup = false;
        });
      } else if (this.document_type_id == 12) {
        let data = this.SortedQualifications?.filter(
          (val) =>
            val.candidate_qualification_id ==
            this.selected.candidate_qualification_id
        )[0];
        let qualificationPayload = {
          document_ids: data.document_ids,
        };
        qualificationPayload?.document_ids?.length
          ? qualificationPayload.document_ids.push(this.candidate_document_id)
          : (qualificationPayload.document_ids = [this.candidate_document_id]);
        this.updateQualification({
          candidate_qualification_id: data.candidate_qualification_id,
          qualificationPayload,
        }).then((res) => {
          this.fetchQualification();
          this.$parent.linkDocument.isShowPopup = false;
        });
      }
      this.resetLastUploadedDocs();
    },
    clearFields() {
      this.confirmation = false;
      this.selected.candidate_license_id = null;
      this.selected.candidate_qualification_id = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.doc-table {
  padding: 5px;
  .head {
    font-size: 12px;
    color: gray;
    margin: 6px 0;
  }
  .data {
    margin-bottom: 2px;
    font-size: 12px;
    color: black;
    font-weight: 400;
  }
}
</style>
