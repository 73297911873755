import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput"; PhoneInputwithCountry
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import { extend } from "vee-validate";

extend("future_date", {
  validate: (value) => {
    const inputDate = new Date(value);
    const todayDate = new Date();
    if (inputDate <= todayDate) {
      return "Given date should be in the future!";
    }
    return true;
  },
});
export default {
  data() {
    return {
      qualTypeId: {
        language: 1,
        nonClinical: 2,
        competency: 4
      }
    }
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    PhoneInputwithCountry,
  },

  computed: {
    ...mapGetters([
      "candidateProfile",

      // Personal Info
      "titles",
      "gender",
      "locations",
      "insideUAELocation",
      "locationCountryInfo",
      "nationalityCountryInfo",
      "locationLanguage",
      "languageProficiency",

      // Professional Info
      "candidateType",
      "specialityFilterByType",
      "subSpecialityFilterBySpeciality",
      "candidateLevelFilterByType",
      "specialInterest",
      "licensingAuthority",
      "getFilteredIssueAuthority",

      // Qualification
      "skill",

      //Availability Info
      "getDisplayData",

      //form edited info
      "getMultiFormEdited",

      // get filtered Special Interest by specialty ID
      "getFilteredSpecialInterest",

      // get filtered issue authority based on candidate and country id
      "getFilteredIssueAuthorityByCandidateTypeAndCountry",
    ]),
  },
  methods: {
    ...mapActions([
      "multiFormEdited",
      "fetchQualificationByCountryAndCandidateType",
      "fetchIssueAuthorityByQualification",
      "fetchSpecialInterestByParams",
      "fetchIssueAuthorityByParams",
    ]),
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
      let hasError = {
        phone: "phone",
        whatsapp: "whatsapp",
        alternate_phone: "alternate_phone",
      };
      if (
        (name == hasError.phone ||
          name == hasError.whatsapp ||
          name == hasError.alternate_phone) &&
        value === ""
      ) {
        this.clearFieldErrors(name, hasError);
      }
      this.formEdited(name);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.profile, name, m(value).format("YYYY-MM-DD"));
      Vue.set(this.payload, name, value ? m(value).format("YYYY-MM-DD") : null);
      this.formEdited(name);
    },
    handleChangeSelect(name, value) {
      let cName = {
        candidate_type: "candidate_type_id",
        speciality: "speciality_id",
        preferred_contact_method: "preferred_contact_method",
        qualification_id: "main_qualification_id",
        country_id: "country_id",
      };
      if (name == cName.candidate_type || name == cName.speciality) {
        Vue.set(
          this.candidateProfile,
          name,
          value ? value.id || value.code || value : null
        );
      }
      Vue.set(this.profile, name, value);
      if (name == cName.preferred_contact_method) {
        let temp = "";
        value.forEach((ele) => {
          temp = temp ? temp + ", " + ele.id : ele.id;
        });
        value = temp;
      }
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name == cName.qualification_id) {
        if (value !== 241) {
          this.profile["other_qualification_name"] = null;
          this.payload["other_qualification_name"] = null;
        }
        this.handleChangeRadio("by_exam", value?.by_exam ? true : null);
        this.fetchIssueAuthorityByQualification({
          qualification_id: value ? value.id || value.code || value : null,
        }).then(res => {
          if (this.getFilteredIssueAuthority?.length) {
            this.handleChangeSelect("issued_by", this.getFilteredIssueAuthority[0])
          } else {
            this.handleChangeSelect("issued_by", null);
          }
        })
      }
      if (name == cName.country_id) {
        this.fetchQualificationByCountryAndCandidateType({
          country_id: value ? value.id || value.code || value : null,
          candidate_type_id: this.candidateProfile?.candidate_type_id,
        });
        // let issueAuthorityPayload = {
        //   candidate_type_id: this.candidateProfile?.candidate_type_id,
        //   country_id: value?.code,
        //   is_qualification: true
        // }
        // this.fetchIssueAuthorityByParams(issueAuthorityPayload);
      }
      if (name == cName.speciality) {
        this.profile["sub_speciality_id"] = null;
        this.payload["sub_speciality_id"] = null;
        let specialInterestPayload = {
          speciality_id: value?.code,
          candidate_type_id: this.candidateProfile?.candidate_type_id
        }
        this.fetchSpecialInterestByParams(specialInterestPayload);
      }
      this.clearFields();
      this.formEdited(name);
      if (name == 'license_country_id') {
        let issueAuthorityPayload = {
          candidate_type_id: this.candidateProfile?.candidate_type_id,
          country_id: value?.code,
          is_licensing: true
        }
        this.fetchIssueAuthorityByParams(issueAuthorityPayload);
      }
      if (name === "speciality_id" && this.candidateProfile?.candidate_type_id === 2) {
        this.handleChangeSelect("sub_speciality_id", {
          code: value?.sub_speciality_id,
          label: value?.sub_speciality,
        })
      }
    },
    handleChangeMultiSelectAsArray(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
            return v.id || v.code || v;
          })
          : null,
      };
      this.formEdited(name);
      let preferred_region = "preferred_regions";
      if (name === preferred_region) {
        this.profile.preferred_country_id =
          this.profile.preferred_country_id &&
          this.profile.preferred_country_id.filter(({ region_id }) =>
            this.payload[name].includes(region_id)
          );
        this.payload.preferred_country_id = this.profile.preferred_country_id
          ? this.profile.preferred_country_id.map((v) => {
            return v.id || v.code || v;
          })
          : null;
      }
    },
    handleChangeMultiSelectAsString(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value
            .map((v) => {
              return v.id || v.code || v;
            })
            .join()
          : null,
      };
      this.formEdited(name);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null || value === undefined) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.profile, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if(name == "full_part_time") {
        this.payload.full_part_time = value?.label  || null
      }
      if (name == "marital_partner_seeking_job") {
        this.profile.spouse_registered = null;
        this.profile.name_of_spouse = null;
        this.profile.spouse_email = null;
      }
      this.formEdited(name);
      if (name === "any_higherqualification_related_to_practice") {
        this.handleChangeRadio("speciality_nurse_or_nurse_practitioner", null);
      }
      if (name === "available") {
        this.profile.available_from = null
      }
    },

    handleFileUpload(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        this.isFileError = false;
        this.$emit("upload", { name: ["file"], value: event.target.files[0] });
      } else {
        this.$refs.fileref.value = "";
        this.isFileError = true;
      }
    },
    formEdited(name) {
      let formFields = {
        specializationInformation: ["speciality_id", "sub_speciality_id", "experience_in_years", "other_speciality", "main", "is_current",],
        personalInformation: [
          "speciality_nurse", "nurse_practitioner", "nationality_ids", "passport_number", "licensing_country_id", "license_level_id",
          "candidate_type_id", "current_job", "available", "notice_period_days",
          "expected_join_date", "available_from", "preferred_regions", "preferred_country_id", "salary_expectation_min", "salary_expectation_max",
          "salary_expectation_currency_id", "current_salary", "current_salary_currency_id", "job_type", "job_time", "email",
          "alternate_email", "phone", "alternate_phone", "whatsapp", "preferred_contact_method", "linkedin_address",
          "skype_id", "personal_website", "working_country_id", "title_id", "first_name", "middle_name", "surname", "known_as", "dob", "gender_id",
          "nationality_id", "birth_place", "marital_status", "marital_partner_seeking_job", "spouse_registered", "name_of_spouse",
          "spouse_email", "dependents", "relocate_family", "subscription_preferences", "new_job_opportunities", "urgent_messages_and_alerts",
          "invitations_relating_to_jobs", "promotions_and_press_releases", "display_data_id", "our_rating", "do_better", "applied_to_other_agencies",
          "subscribed", "delete_my_account", "level_id", "nurse_registration_type", "years_at_level", "band_id"
        ],
        splInterest: ["special_interest_id", "other_special_interest"],
        licenseInformation: [
          "license_country_id", "license__issue_authority_id", "License_issue_date", "specialist_registration",
          "date_acquired", "local_license", "license_number","student_license","student_license_reg_date","student_license_reg_type","student_licensesed_country"
        ],
        qualification: [
          "country_id", "main_qualification_id", "by_exam", "qualified_date", "issued_by", "distance_learning", "other_issue_authority","student_speciality_id","full_part_time","course_start_date","predicted_completion_date"
        ],
        langInfo: ["languages_spoken", "language_proficiency"],
        NonMedicalInfos: ["qualification_name", "score", "others_name", "others_score", "qual_month_year"],
        preferredDeptInformation: ["department_id", "experience_in_yrs", "last_date_of_practice"],
        ExperienceInfos: ["org_type_id", "organisation_location_id", "organisation_additional_info", "organisation_nature", "organisation_bedcount", "direct_reports_count", "is_current_org", "start_month_year", "end_month_year", "exp_level_id", "designation"],
        languageQualification: ["language_id", "language_exam", "test_type", "required_score_id"],
        competencyInfo: ["qualification_name", "score", "others_name", "others_score", "qual_month_year"],
      };
      let data = {};
      for (let key in formFields) {
        let obj = formFields[key];
        if (obj.includes(name)) {
          data[key] = true;
        }
      }
      this.multiFormEdited(data);
    },
  },
};
